<template>
  <div id="application-buttons" class="flex flex-col sm:flex-row sm:justify-end py-4 px-5 sm:px-6 lg:px-9">
    <vs-button
      v-if="appStatus === 3"
      color="danger"
      icon="delete"
      icon-before
      class="mb-2 sm:mb-0 hover:w-full sm:w-3.5/12"
      @click="deleteApplication()"
      >Delete</vs-button
    >
    <vs-button
      v-if="appStatus === 0 || appStatus === 1 || appStatus === 2"
      color="danger"
      icon="thumb_down_alt"
      icon-before
      class="mb-2 sm:mb-0 w-full sm:w-3.5/12"
      @click="handleApplication(3, true)"
      >Decline</vs-button
    >
    <vs-button
      v-if="appStatus === 3 && empReject"
      color="primary"
      icon="thumb_up_alt"
      icon-before
      class="mb-2 sm:mb-0 w-full sm:w-3.5/12 sm:ml-3"
      @click="handleApplication(1, false)"
      >Re-invite</vs-button
    >

    <vs-button v-if="appStatus === 1" color="grey" icon="pending" disabled icon-before class="mb-2 sm:mb-0 sm:ml-3 w-full sm:w-3.5/12"
      >Invite pending</vs-button
    >
    <vs-button
      v-if="appStatus === 0"
      color="primary"
      icon="playlist_add"
      icon-before
      class="mb-2 sm:mb-0 w-full sm:w-3.5/12 sm:ml-3"
      @click="handleApplication(2, false)"
      >Shortlist</vs-button
    >
    <vs-button
      v-if="appStatus === 2"
      color="grey"
      icon="playlist_remove"
      icon-before
      class="mb-2 sm:mb-0 w-full sm:w-3.5/12 sm:ml-3"
      @click="handleApplication(0, false)"
      >Delist</vs-button
    >

    <!-- <vs-button class="mb-2 w-3.5/12" color="grey" select @click="closeProfile">Close</vs-button> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedApp: this.$store.state.activeApp,
    };
  },
  computed: {
    appStatus() {
      return this.selectedApp.status;
    },
    empReject() {
      if (this.selectedApp.employerReject !== null) {
        return this.selectedApp.employerReject;
      } else {
        return false;
      }
    },
  },
  methods: {
    handleApplication(status, reject) {
      if (reject) {
        this.selectedApp.invitationAccepted = false;
      }
      this.selectedApp.status = status;
      this.selectedApp.read = true;
      this.selectedApp.employerReject = reject;
      this.selectedApp.updatedAt = new Date();
      this.$store.dispatch('applicationsModule/patch', this.selectedApp);
      this.$emit('refresh');
    },
    deleteApplication() {
      this.$store.dispatch('applicationsModule/delete', this.selectedApp.id);
      this.$emit('refresh');
      this.$store.dispatch('toggleSidebar', false);
    },
    closeProfile() {
      this.$store.dispatch('toggleSidebar', false);
    },
  },
};
</script>
