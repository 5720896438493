<script>
export default {
  name: 'DashboardTour',
  data() {
    return {
      dashboardTourSteps: [
        {
          target: window.innerWidth > 1199 ? '.vs-sidebar-item-active' : '.navbar-custom',
          header: {
            title: 'This is your <strong>Account Dashboard</strong>',
          },
          content: `The Dashboard provides you with an overview of the activity on your account.`,
          params: {
            enableScrolling: false,
            placement: 'auto',
          },
        },
        {
          target: '#dashboard-applications',
          content: `Here you can see the number of applications you have.`,
          params: {
            enableScrolling: false,
            placement: 'auto',
          },
        },
        {
          target: '#dashboard-active-chats',
          content: `And the number active chats you have.`,
          params: {
            enableScrolling: false,
            placement: 'auto',
          },
        },

        {
          target: '#current-jobs',
          content: `This table shows you all your current Job Posts, as well as the number of applicants that have applied, been shortlisted, or invited to each job.`,
          params: {
            enableScrolling: false,
            placement: 'auto',
          },
        },
        {
          target: '#dashboard-calendar',
          content: `This calendar displays the start dates for all your Job Posts as well as when they expire.`,
          params: {
            enableScrolling: true,
            placement: 'auto',
          },
        },
        {
          target: '#latest-applicants',
          content: `Here you will find all the latest applicants for any of your job posts.<br><br> Once you've interacted an application, it will be removed from this list.`,
          params: {
            enableScrolling: true,
            placement: 'auto',
          },
        },
      ],
      tourCallbacks: {
        onSkip: this.markTourAsSeen,
        onFinish: this.markTourAsSeen,
      },
    };
  },
  mounted() {
    const employer = this.$store.getters['employerModule/getUser'];
    if (!employer.dashboardTours || !employer.dashboardTours.dashboardTourSeen) {
      setTimeout(() => {
        try {
          this.$tours['dashboardTour'].start();
        } catch (error) {
          return;
        }
      }, 1000);
    }
  },
  methods: {
    markTourAsSeen() {
      this.$store.dispatch('employerModule/patch', { dashboardTours: { dashboardTourSeen: true } });
    },
  },
};
</script>
<template>
  <v-tour name="dashboardTour" :steps="dashboardTourSteps" :callbacks="tourCallbacks"></v-tour>
</template>

<style lang="scss">
.v-step,
.v-step__header,
.v-step__arrow,
.v-step__arrow::before {
  background: #adca63 !important;
}
.v-step {
  border-radius: 10px !important;
  z-index: 99999 !important;
}
.v-step__header {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  margin-top: 0 !important;
}

.v-step__button {
  border-radius: 5px !important;
  border-top-width: 0.1rem !important;
  font-size: 1rem !important;
}
</style>
