<template>
  <div>
    <DashboardTour />
    <vs-col class="w-full sm:w-3.5/5 lg:w-2/3 pl-0 pr-0 sm:pr-5">
      <vs-row
        ><vs-card class="mr-0 modal-text border-solid border-2 border-gray-200 bg-rag-green bg-opacity-20">
          <div class="flex flex-col items-center justify-center p-5 sm:p-3 lg:p-8 xl:p-5"
            ><p class="font-bold text-xl">Hello {{ employer.name }}!</p>
            <p v-if="jobsNoApps" class="text-base text-center text-dark-grey mb-5"
              >You have no new activity. Visit the Guide Board to invite guides to apply for your job posts</p
            >
            <p v-else-if="jobsAndApps" class="text-base text-dark-grey mb-5 text-center">{{ unreadAppsMsg }}</p>
            <p v-else-if="!profileCheck" class="text-base text-dark-grey mb-5 text-center">To get started please complete your profile</p>
            <!-- <p v-else class="text-base text-dark-grey mb-5 text-center">We have given you two free job post credits to get started 🎁!</p> -->
            <p v-else class="text-base text-dark-grey mb-5 text-center"
              >To get started purchase a job post credit if you need to and create your first job post</p
            >
            <vs-button @click="navigateToRoute">{{ buttonText }}</vs-button>
          </div>
        </vs-card>
      </vs-row>
      <vs-row v-if="!jobsAndApps">
        <div class="cursor-pointer w-full" @click="$router.push('/pages/resume')">
          <vs-card class="mr-0 modal-text border-solid border-2 border-gray-200">
            <div class="flex flex-col items-center justify-center p-5 xl:p-1.5">
              <p class="font-semibold text-xl text-center">Available candidates</p>
              <DataCards />
            </div>
          </vs-card>
        </div>
      </vs-row>
      <vs-row class="w-full flex-col sm:flex-row">
        <vs-col v-if="jobsAndApps" class="pl-0 pr-0 sm:pr-3 w-full sm:w-1/2">
          <div class="cursor-pointer" @click="$router.push('/pages/applications')"
            ><vs-card id="dashboard-applications" class="border-solid border-2 border-gray-200 flex sm:block">
              <div class="flex items-center justify-center ml-5 md:ml-0 p-5 xl:p-8"
                ><feather-icon icon="UsersIcon" svg-classes="w-16 h-16" class="pr-4 text-rag-green"></feather-icon
                ><div
                  ><p class="font-bold text-base lg:text-2xl">{{ numberOfApps }}</p
                  ><p class="font-semibold text-base lg:text-2xl text-dark-grey">Applications</p></div
                >
              </div>
            </vs-card></div
          >
        </vs-col>

        <vs-col v-if="jobsAndApps" class="pr-0 pl-0 sm:pl-3 w-full sm:w-1/2">
          <div class="cursor-pointer" @click="$router.push('/pages/chat')">
            <vs-card id="dashboard-active-chats" class="border-solid border-2 border-gray-200 flex sm:block">
              <div class="flex items-center justify-center ml-5 md:ml-0 p-5 xl:p-8"
                ><feather-icon icon="MessageCircleIcon" svg-classes="w-16 h-16" class="pl-4 icon-flipped text-rag-green"></feather-icon
                ><div
                  ><p class="font-bold text-base lg:text-2xl">{{ numberOfChats }}</p
                  ><p class="font-semibold text-base lg:text-2xl text-dark-grey">Chats</p></div
                >
              </div>
            </vs-card>
          </div>
        </vs-col>
      </vs-row>
      <vs-row v-if="jobsExist" id="current-jobs">
        <CurrentJobs :jobs="jobs" :applications="applications" />
      </vs-row>
    </vs-col>
    <vs-col class="w-full sm:w-1/3 p-0">
      <vs-row id="dashboard-calendar" class="w-full"><DashboardCalendar :jobs="jobs" /></vs-row>
      <vs-row v-if="jobsAndApps" id="latest-applicants" class="pb-10 sm:pb-0"><LatestApplicants /></vs-row>
    </vs-col>
  </div>
</template>

<script>
import CurrentJobs from '../components/dashboard-components/CurrentJobs.vue';
import DashboardCalendar from '../components/dashboard-components/DashboardCalendar.vue';
import LatestApplicants from '../components/dashboard-components/LatestApplicants.vue';
import DashboardTour from '../components/tours/DashboardTour.vue';
import DataCards from '../components/dashboard-components/DashboardDataCards.vue';
export default {
  name: 'Dashboard',
  components: {
    CurrentJobs,
    LatestApplicants,
    DashboardCalendar,
    DashboardTour,
    DataCards,
  },
  data() {
    return {
      employer: this.$store.getters['employerModule/getUser'],
      applications: this.$store.state.applicationsModule.data,
      activeChats: this.$store.state.chatModule.data,
      jobs: this.$store.state.jobsModule.data,
    };
  },
  computed: {
    unreadAppsMsg() {
      return `You have ${this.unreadApps > 0 ? this.unreadApps : 'no'} new unread application${this.unreadApps === 1 ? '' : 's'}`;
    },
    unreadApps() {
      let unread = [];
      Object.values(this.$store.state.applicationsModule.data).forEach((app) => {
        if (!app.read) {
          unread.push(app);
        }
      });
      return unread.length;
    },
    profileCheck() {
      return this.$store.getters['companiesModule/getComp'] && this.$store.getters['employerModule/billingDetailsCheck'];
    },
    numberOfApps() {
      return Object.keys(this.applications).length;
    },
    numberOfChats() {
      return Object.keys(this.activeChats).length;
    },
    numberOfJobs() {
      return Object.keys(this.jobs).length;
    },
    noJobs() {
      return this.numberOfJobs === 0;
    },
    jobsExist() {
      return this.numberOfJobs > 0;
    },
    jobsNoApps() {
      return this.numberOfJobs > 0 && this.numberOfApps === 0;
    },
    jobsAndApps() {
      return this.numberOfJobs > 0 && this.numberOfApps > 0;
    },
    buttonText() {
      let text;
      if (!this.profileCheck) {
        text = 'Go to Profile';
        return text;
      }
      if (this.jobsNoApps) {
        text = 'Visit the Guide Board';
      }
      if (this.jobsAndApps) {
        text = 'View Applications';
      }
      if (this.noJobs) {
        text = 'Post a Job';
      }
      return text;
    },
  },
  methods: {
    navigateToRoute() {
      if (!this.profileCheck) {
        return this.$router.push('/pages/profile');
      }
      if (this.jobsNoApps) {
        return this.$router.push('/pages/resume');
      }
      if (this.jobsAndApps) {
        return this.$router.push('/pages/applications');
      }
      return this.$router.push('/pages/jobs');
    },
  },
};
</script>

<style lang="scss">
.vs-card--content {
  margin-bottom: 0px !important;
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}
</style>
