<template>
  <div class="w-full">
    <vs-card
      class="border-solid border-2 border-gray-200 w-full sm:w-dashboard-card-tablet sm:h-dashboard-card-tablet lg:w-dashboard-card lg:h-dashboard-card p-3 overflow-y-auto"
      ><vs-row class="flex justify-between"
        ><p class="text-lg text-rag-green">Latest Applicants</p
        ><p class="text-base underline cursor-pointer" @click="toApplications()">See all</p></vs-row
      >
      <div v-if="sortedUnreadApps.length === 0" class="h-64 w-full">
        <p class="text-center relative top-1/2 text-base mt-6 text-gray-400">You have no new applicants</p>
      </div>
      <div v-else class="grid grid-cols-1">
        <div v-for="(app, index) in sortedUnreadApps" :key="index">
          <div
            v-if="getProfileStatus(app.userId)"
            class="relative py-4 flex items-center space-x-3 cursor-pointer"
            @click="openProfile(app)"
          >
            <div class="flex-shrink-0">
              <img class="h-10 w-10 rounded-full object-cover" :src="userImage(app.userId)" alt="profile picture" />
            </div>
            <div class="flex-1 min-w-0">
              <span class="absolute inset-0" aria-hidden="true" />
              <p class="text-sm font-medium text-gray-900">
                {{ app.userData.userName || getUserName(app.userId) }}
              </p>
              <p class="text-sm text-gray-500 truncate">
                {{ app.jobData.jobTitle }}
              </p>
            </div>
            <feather-icon icon="UsersIcon" svg-classes="w-5 h-5" class="text-gray-500 pl-3"></feather-icon>
          </div>
        </div>
      </div>
    </vs-card>
    <portal to="rag-sidebar">
      <chat-side
        v-if="isChatSidebarActive"
        :click-not-close="false"
        :active-chat-user="activeChatUser"
        :is-chat-pinned="isChatPinned"
        :user-data="userData"
        :selected-contact="selectedContact"
        :typed-message="typedMessage"
        :settings="settingsTwo"
        @close="closeChat"
        @update-active-chat-user="updateActiveChatUser"
      ></chat-side>
      <div v-else>
        <profile-user-profile ref="userProfile" :user="selected"></profile-user-profile>
        <portal to="rag-sidebar-footer">
          <div id="appProfileActionBtns">
            <ApplicationButtons v-if="activeApp" />
          </div>
        </portal>
      </div>
    </portal>
    <portal to="chatButton">
      <vs-button id="appProfileMsgBtn" icon="mail_outline" icon-before @click="isChatSidebarActive = !isChatSidebarActive"
        >Message
      </vs-button>
    </portal>
  </div>
</template>

<script>
import ProfileUserProfile from '../guide-profile-components/ProfileUserProfile.vue';
import ChatSide from '../../apps/chat/ChatSide.vue';
import ApplicationButtons from '../application-components/ApplicationButtons.vue';
export default {
  name: 'LatestApplicants',
  components: {
    ProfileUserProfile,
    ChatSide,
    ApplicationButtons,
  },
  data() {
    return {
      guides: this.$store.getters['userModule/getUser'],
      isChatSidebarActive: false,
      activeChatUser: '',
      selectedApp: null,
      isChatPinned: false,
      selected: {},
      userData: {},
      typedMessage: '',
      settingsTwo: {
        maxScrollbarLength: 100,
        wheelSpeed: 0.7,
      },
    };
  },

  computed: {
    activeApp() {
      return this.$store.state.activeApp;
    },
    sortedUnreadApps() {
      let applications = [];
      Object.values(this.$store.state.applicationsModule.data).forEach((app) => {
        if (!app.read && app.status !== 1) {
          applications.push(app);
        }
      });

      applications.sort((x, y) => {
        let timeX = x.createdAt;
        let timeY = y.createdAt;
        return timeY - timeX;
      });
      return applications;
    },
  },
  destroyed: function () {
    document.removeEventListener('keyup', this.myMethod);
  },
  created() {
    document.addEventListener('keyup', this.myMethod);
  },

  methods: {
    toApplications() {
      this.$router.push('/pages/applications');
    },
    userImage(userId) {
      return this.guides[userId].profileImage;
    },
    getUserName(userId) {
      let user = this.$store.getters['userModule/getUser'][userId];
      if (user.firstName && user.firstName !== '' && user.lastName && user.lastName !== '') {
        return `${user.firstName} ${user.lastName}`;
      } else {
        return user.fullName;
      }
    },
    getProfileStatus(userId) {
      let user = this.$store.getters['userModule/getUser'][userId];
      if (user) {
        if (!user.profileDeactivated) {
          if (user.active === true) {
            return true;
          } else {
            return false;
          }
        }
        if (user.profileDeactivated) {
          if (user.profileDeactivated === true || user.active === false) {
            return false;
          } else {
            return true;
          }
        }
      }
    },
    openProfile(app) {
      this.isChatSidebarActive = false;
      this.selectedApp = app;
      this.$store.dispatch('setActiveApp', this.selectedApp);
      let user = this.$store.getters['userModule/getUser'][app.userId];
      if (user) {
        this.selected = user;
        this.$store.dispatch('toggleSidebar', true);
        this.activeChatUser = app.applicationsId;
        this.isChatPinned = true;
        this.selectedContact = app;
        this.typedMessage = '';
        this.userData = this.$store.getters['chatModule/chatDataOfUser'](this.activeChatUser);
      } else {
        this.$vs.notify({
          color: 'danger',
          title: 'User does not exist',
        });
        return;
      }
    },
    updateActiveChatUser(contactId) {
      this.activeChatUser = contactId.applicationsId;
      this.selectedContact = contactId;
      let chatData = this.$store.getters['chatModule/chatDataOfUser'](this.activeChatUser);
      this.userData = chatData;
      this.isChatActive = true;
      if (chatData && chatData.userCount > 0) {
        chatData.userCount = 0;
        this.$store.dispatch('chatModule/set', chatData);
      }
      if (chatData) this.isChatPinned = chatData.isPinned;
      else this.isChatPinned = true;
      this.typedMessage = '';
    },
    myMethod(event) {
      if (event.keyCode === 27) {
        this.$store.dispatch('toggleSidebar', false);
      }
    },
    closeChat() {
      this.isChatSidebarActive = false;
    },
  },
};
</script>
